import { useSelector } from 'react-redux';
import { authProfile } from 'redux/auth/selectors';
import { citiesGlobalsSelector } from 'redux/globals/selectors';

export interface City {
  id?: number;
  countryId?: number;
  lat?: number;
  lng?: number;
  name?: string;
  timeZone?: string;
  twoLetterISOCode?: string;
}

export default function useUserCityPosition() {
  const profile = useSelector(authProfile);
  const cities = useSelector(citiesGlobalsSelector);
  const city = cities.find((c: City) => c.id === profile.cityID);

  return {
    lat: city.lat && city.lat !== 0 ? city.lat : null,
    lng: city.lng && city.lng !== 0 ? city.lng : null,
  };
}
