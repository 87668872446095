/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable eqeqeq */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Flex, Form, Modal, Tooltip } from 'antd';
import { InfoCircleOutlined, RedoOutlined } from '@ant-design/icons';
import GoogleMap from 'routes/app/zone/components/map/GoogleMap';
import { Point, useUpdateZone, useZones, Zone } from 'services/ZoneService';
import Card from 'components/card';
import { DebouncedInputField, DebouncedTextAreaField } from 'components/Forms';
import useUserCityPosition from 'hooks/useUserCity';
import { ZipCodeSelector } from './components/zipCodeSelector';

interface ZoneEditParams {
  id?: string;
}

const ZoneEdit = () => {
  const { id } = useParams<ZoneEditParams>();
  const history = useHistory();
  const { lat, lng } = useUserCityPosition();
  const {
    zones: { data: zones, isSuccess, isLoading, isFetching },
    getZoneById,
  } = useZones();
  const { mutate: updateZone } = useUpdateZone();

  const [polygon, setPolygon] = useState<Point[]>();
  const [name, setName] = useState<string>();
  const [detail, setDetail] = useState<string>();
  const [interurbanId, setInterurbanId] = useState<string>();
  const [open, setOpen] = useState(false);
  const [zipCodes, setZipCodes] = useState<string[]>([]);

  const zone = useMemo(() => {
    if (!isLoading && !isFetching && id) return getZoneById(id);
    return { name: '', detail: '' };
  }, [getZoneById, isLoading, isFetching, id]);

  const zoneZipCodes = useMemo(
    () => (zone && zone.zipCodes ? zone.zipCodes : []),
    [zone],
  );

  useEffect(() => {
    if (zone) {
      setName(zone.name);
      setDetail(zone.detail);
      setInterurbanId(zone.cdcId);
    }
  }, [zone]);

  if (!isSuccess || !id) return history.push('/zones');

  const handleSubmit = () => {
    const geoJson = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          polygon?.map((p: Point) => [parseFloat(p.lng), parseFloat(p.lat)]),
        ],
      },
      properties: {},
    };

    const zoneToUpdate = {
      ...zone,
      detail,
      geoJson: JSON.stringify(geoJson),
      name,
      cdcId: interurbanId,
      zipCodes,
    };

    updateZone(zoneToUpdate);
    history.goBack();
  };

  return (
    <>
      <Modal
        title={`¿Confirmar edición de zona ${name}?`}
        okText="Confirmar"
        cancelText="Volver"
        onOk={handleSubmit}
        onCancel={() => setOpen(false)}
        onClose={() => setOpen(false)}
        open={open}
      />
      <Flex
        vertical={false}
        gap={16}
        style={{ width: '100%', minHeight: '100%' }}
      >
        <Flex vertical style={{ width: '20%' }}>
          <Card title="Datos de la zona">
            <Flex justify="space-between">
              <label>Nombre:</label>
              {name != zone.name && (
                <RedoOutlined onClick={() => setName(zone.name)} />
              )}
            </Flex>
            <Form.Item
              validateStatus={name != zone.name ? 'warning' : 'success'}
            >
              <DebouncedInputField
                placeholder="Nombre"
                value={name}
                setValue={setName}
              />
            </Form.Item>
            <Flex justify="space-between">
              <label>Descripción:</label>
              {detail != zone.detail && (
                <RedoOutlined onClick={() => setDetail(zone.detail)} />
              )}
            </Flex>
            <Form.Item
              validateStatus={detail != zone.detail ? 'warning' : 'success'}
            >
              <DebouncedTextAreaField
                rows={3}
                placeholder="Informacion adicional de esa zona"
                value={detail}
                setValue={setDetail}
              />
            </Form.Item>
            <Flex justify="space-between">
              <label>Identificador inter-urbano:</label>
              {interurbanId != zone.cdcId && (
                <RedoOutlined onClick={() => setInterurbanId(zone.cdcId)} />
              )}
            </Flex>
            <Form.Item
              validateStatus={
                interurbanId != zone.cdcId ? 'warning' : 'success'
              }
            >
              <DebouncedInputField
                placeholder="Número de identificación"
                value={interurbanId}
                setValue={setInterurbanId}
                suffix={
                  <Tooltip title="El identificador de inter-urbano es un número necesario para el cálculo de las tarifas inter-urbano, entre ciudades, o también llamadas troncales.">
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Card>
          <ZipCodeSelector
            initialZipCodes={zoneZipCodes}
            selectedZipCodes={zipCodes}
            setZipCodes={setZipCodes}
          />
        </Flex>
        <div style={{ width: '80%', minHeight: '100%' }}>
          <GoogleMap
            arePolygonsLoading={isLoading || isFetching}
            initialCenter={{
              lat: lat ?? -34.601692,
              lng: lng ?? -58.431461,
            }}
            editablePolygon={zone}
            polygons={zones.filter((z: Zone) => z.id != zone.id)}
            onPolygonChange={setPolygon}
          />
        </div>
      </Flex>
      <Button
        type="primary"
        onClick={() => setOpen(true)}
        disabled={!name || !detail}
        size="large"
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          marginRight: 80,
          marginBottom: 60,
        }}
      >
        Guardar Cambios
      </Button>
    </>
  );
};

export default ZoneEdit;
