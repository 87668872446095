/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Flex, Form, Input } from 'antd';
import Modal from 'components/modal';
import { authProfile } from 'redux/auth/selectors';
import GoogleMap from 'routes/app/zone/components/map/GoogleMap';
import Card from 'components/card';
import { useCreateGroupingZone, useZones, Zone } from 'services/ZoneService';
import useUserCityPosition from 'hooks/useUserCity';
import { ChildrenZonesSelector } from './components/childrenZonesSelector';

const { TextArea } = Input;

const GroupingZoneAdd = () => {
  const history = useHistory();
  const profile = useSelector(authProfile);
  const { lat, lng } = useUserCityPosition();
  const {
    zones: { data: zones, isSuccess, isLoading, isFetching },
  } = useZones();
  const { mutate: createGroupingZone } = useCreateGroupingZone();

  const [name, setName] = useState<string>();
  const [detail, setDetail] = useState<string>();
  const [open, setOpen] = useState(false);
  const [childrenZonesIds, setChildrenZonesIds] = useState<number[]>([]);

  const childrenZones = useMemo(
    () => zones.filter((z: Zone) => !!z.id && childrenZonesIds.includes(z.id)),
    [childrenZonesIds, zones],
  );

  if (!isSuccess) return history.push('/zones');

  const handleSubmit = () => {
    const tenant = profile?.tenantId;
    const logisticOperatorId = profile?.logisticOperatorID;

    const zone = {
      name,
      detail,
      tenant,
      logisticOperatorId,
      childrenZones: childrenZonesIds,
    };

    createGroupingZone(zone);
    history.goBack();
  };

  return (
    <>
      <Modal
        title={`¿Confirmar creación de zona agrupadora ${name}?`}
        okText="Confirmar"
        cancelText="Volver"
        onOk={handleSubmit}
        onCancel={() => setOpen(false)}
        onClose={() => setOpen(false)}
        open={open}
      />
      <Flex
        vertical={false}
        gap={16}
        style={{ width: '100%', minHeight: '100%' }}
      >
        <Flex vertical style={{ width: '20%' }}>
          <Card title="Datos de la zona">
            <label>Nombre:</label>
            <Form.Item validateStatus="warning">
              <Input
                placeholder="Nombre"
                onBlur={(event) => setName(event.target.value)}
                onPressEnter={({ target }) => {
                  setName((target as HTMLInputElement).value);
                }}
              />
            </Form.Item>
            <label>Descripción:</label>
            <Form.Item validateStatus="warning">
              <TextArea
                rows={6}
                placeholder="Informacion adicional de esa zona"
                onBlur={(event) => setDetail(event.target.value)}
                onPressEnter={({ target }) => {
                  setDetail((target as HTMLInputElement).value);
                }}
              />
            </Form.Item>
          </Card>
          <ChildrenZonesSelector
            selectedChildrenZones={childrenZones}
            setChildrenZonesIds={setChildrenZonesIds}
          />
        </Flex>
        <div style={{ width: '80%', minHeight: '100%' }}>
          <GoogleMap
            arePolygonsLoading={isLoading || isFetching}
            initialCenter={{
              lat: lat ?? -34.601692,
              lng: lng ?? -58.431461,
            }}
            polygons={childrenZones}
          />
        </div>
      </Flex>
      <Button
        type="primary"
        onClick={() => setOpen(true)}
        disabled={!name || !detail || childrenZonesIds.length < 1}
        size="large"
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          marginRight: 70,
          marginBottom: 60,
        }}
      >
        Crear nueva Zona agrupadora
      </Button>
    </>
  );
};

export default GroupingZoneAdd;
