import React, { useState, useRef, useEffect } from 'react';
import { PACKET_VALUE_THRESHOLD } from 'constants/defaultValues';
import { isArrivableAtPaQueryPointStatus } from 'constants/packages';
import API from 'constants/api';
import rest from 'util/Api';
import { useSelector } from 'react-redux';
import {
  paquerypointViewPaquerypointIDSelector,
  paquerypointViewPaquerypointSelector,
} from 'redux/paquerypointView/selectors';
import { useDebounce } from 'util/Hooks';
import EntryPacketView, { ERROR_STATUS } from '@paquery-team/lib-arrive-packet';
import { Card, notification } from 'antd';
import PackageSelectModal from 'components/packageSelectModal';
import { fetchPackageWithExactExtCode } from 'constants/operations';
import { selectGlobals } from 'redux/globals/selectors';
import useMarketplaces from 'redux/marketplaces';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import useStyles from '../style';

const WAIT_INTERVAL = 600;

const ArrivePacket = () => {
  useMarketplaces({ initialize: true });
  const classes = useStyles();
  const globals = useSelector(selectGlobals);
  const paqueryPointId = useSelector(paquerypointViewPaquerypointIDSelector);
  const paquerypoint = useSelector(paquerypointViewPaquerypointSelector);
  const marketplaces = useSelector(marketplacesItemsSelector);
  const [packetResponse, setPacketResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [externalCode, setExternalCode] = useState('');
  const [packageOptions, setPackageOptions] = useState([]);
  const [packageModal, setPackageModal] = useState(false);
  const inputExternalCode = useRef(null);
  const debouncedExternalCode = useDebounce(externalCode, WAIT_INTERVAL);

  const getPackage = async (code) => {
    const response = await fetchPackageWithExactExtCode(code);
    if (response instanceof Error) {
      setLoading(false);
      inputExternalCode.current.focus();
      inputExternalCode.current.select();
      inputExternalCode.current.value = '';
      return notification.error({
        message: 'Ha ocurrido un error al encontrar el paquete',
        description: `No se ha encontrado el paquete con el código ${code}`,
      });
    }
    return response;
  };

  const arrivePacket = async ({ code }, extCode) => {
    try {
      let isBundle = false;
      setPackageModal(false);
      if (extCode) {
        const isBundleParams = new URLSearchParams({ externalCode: extCode });
        const isBundleResponse = await rest.apiAxios.get(
          `${API.packages.isPackageBundle}?${isBundleParams.toString()}`,
        );
        isBundle = isBundleResponse.data.data;
      }
      const searchParams = new URLSearchParams({
        searchArrived: isBundle ? extCode : code,
        paqueryPointId,
      });
      const register = await rest.apiAxios.put(
        `${API.paqueryPoint.assign}?${searchParams.toString()}`,
      );
      if (rest.isSuccessResponse(register)) {
        if (register.data.data instanceof Array) {
          setPacketResponse(register.data.data[0]);
          if (register.data.data[0].estimatedCost > PACKET_VALUE_THRESHOLD) {
            notification.warn({
              message: 'Notificación de valor',
              description: `El paquete con codigo externo ${externalCode} tiene un valor elevado.`,
              className: classes.highValueNotificationContainer,
              placement: 'topLeft',
              icon: <div />,
            });
          }
        } else if (register.data.data instanceof Object) {
          setPacketResponse(register.data.data);
          if (register.data.data.estimatedCost > PACKET_VALUE_THRESHOLD) {
            notification.warn({
              message: 'Notificación de valor',
              description: `El paquete con codigo externo ${externalCode} tiene un valor elevado.`,
              className: classes.highValueNotificationContainer,
              placement: 'topLeft',
              icon: <div />,
            });
          }
        }
      } else {
        throw new Error(register.data.message);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      if(error.response.data.code == 2){
        setPacketResponse({
          status: ERROR_STATUS,
          title: 'Liberar al Driver',
          message: error.response.data.message,
        });            
      }
      else{
      setPacketResponse({ status: ERROR_STATUS, message: error.message });
      }
    } finally {
      // TODO: no se limpia bien el input
      setLoading(false);
      inputExternalCode.current.focus();
      inputExternalCode.current.select();
      inputExternalCode.current.value = '';
    }
  };

  useEffect(() => {
    const handleSubmit = async (extCode) => {
      setPacketResponse(null);
      if (!extCode || extCode === '') {
        return;
      }
      setLoading(true);
      let receivedExternalCode = extCode.trim();
      // Check if the typed extCode is a scanned QR
      try {
        const qrCode = JSON.parse(extCode.trim());
        if (qrCode.id) {
          receivedExternalCode = qrCode.id;
        }
      } catch (e) {
        // not QR and it's okay
      }
      const fetchedPackets = await getPackage(receivedExternalCode);
      if (!fetchedPackets) {
        return;
      }
      if (fetchedPackets.length === 1) {
        arrivePacket(...fetchedPackets, extCode);
        return;
      }
      const filteredPackages = fetchedPackets.filter((packet) =>
        isArrivableAtPaQueryPointStatus(packet.status),
      );
      const suggestedPackageOptions = filteredPackages.map((packet) => {
        return {
          ...packet,
          marketplace: marketplaces.find((mkp) => mkp.id === packet.ownerID)
            ?.name,
          destinationAddress:
            packet.shippingScheduleDestination.shippingAddress.addressDetail,
          statusDescription: globals.packages.status.find(
            (state) => state.value === packet.status,
          ).name,
        };
      });
      if (suggestedPackageOptions.length === 0) {
        notification.info({
          title: 'Información',
          message: `No se ha encontrado el paquete con código externo: ${receivedExternalCode}`,
        });
        setPackageOptions([]);
        setLoading(false);
        return;
      }
      setPackageModal(true);
      setPackageOptions(suggestedPackageOptions);
    };
    handleSubmit(debouncedExternalCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedExternalCode]);

  return (
    <Card>
      <PackageSelectModal
        visible={packageModal}
        packages={packageOptions}
        handlePackageSelection={arrivePacket}
        handleCloseModal={() => {
          setPackageModal(false);
          setPackageOptions([]);
          setLoading(false);
          inputExternalCode.current.focus();
          inputExternalCode.current.select();
          inputExternalCode.current.value = '';
        }}
      />
      <EntryPacketView
        inputExternalCode={inputExternalCode}
        onChange={(event) => setExternalCode(event.target.value)}
        loading={loading}
        packetResponse={packetResponse}
        title={`Arribar a ${paquerypoint.detail}`}
      />
    </Card>
  );
};

export default ArrivePacket;
