import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/userView/slice';
import { actions as userListActions } from 'redux/users/slice';
import { actions as storeActions } from 'redux/store/slice';
import useUserview from 'redux/userView/index';
import useMarketplaces from 'redux/marketplaces';
import usePaqueryPoints from 'redux/paquerypoint';
import useStore from 'redux/store';
import {
  userviewAvatarSelector,
  userviewUserSelector,
  userviewLoadedSelector,
} from 'redux/userView/selectors';
import {
  rolesGlobalsSelector,
  countriesGlobalsSelector,
} from 'redux/globals/selectors';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import { paquerypointsItemsSelector } from 'redux/paquerypoint/selectors';
import { storeItemsSelector, storeLoadedSelector } from 'redux/store/selectors';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { message, notification } from 'antd';
import rest, { getData } from 'util/Api';
import { IMAGE_REGEX } from 'constants/defaultValues';
import { MarketplaceRoles, StoreRoles } from 'constants/roles';
import API from 'constants/api';
import SITELINKS from 'constants/sitelinks';
import User from '../user';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Es necesario indicar el nombre del paquer'),
  lastName: Yup.string().required(
    'Es necesario indicar el apellido del paquer',
  ),
  phone: Yup.number().required('Es necesario indicar el número de teléfono'),
  email: Yup.string()
    .email('El formato de correo no es correcto')
    .required('Es necesario indicar el correo electrónico'),
  userRoleID: Yup.number()
    .typeError('Es necesario indicar el rol')
    .required('Es necesario indicar el rol'),
  marketplaceID: Yup.number().when('userRoleID', {
    is: (userRoleID) =>
      MarketplaceRoles.includes(userRoleID) || StoreRoles.includes(userRoleID),
    then: Yup.number()
      .typeError('Se necesita indicar Marketplace')
      .required('Se necesita indicar Marketplace'),
    otherwise: Yup.number().nullable(),
  }),
  storeID: Yup.number().when('userRoleID', {
    is: (userRoleID) => StoreRoles.includes(userRoleID),
    then: Yup.number()
      .typeError('Se necesita indicar Tienda')
      .required('Se necesita indicar Tienda'),
    otherwise: Yup.number().nullable(),
  }),
});

const defaultValues = {
  name: '',
  lastName: '',
  phone: null,
  email: '',
};

const disabledInputs = {
  store: true,
  email: true,
};

const UserEdit = () => {
  useUserview();
  useMarketplaces({ initialize: true });
  usePaqueryPoints({ initialize: true });
  useStore();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const loaded = useSelector(userviewLoadedSelector);
  const avatar = useSelector(userviewAvatarSelector);
  const countries = useSelector(countriesGlobalsSelector);
  const marketplaces = useSelector(marketplacesItemsSelector);
  const paqueryPoints = useSelector(paquerypointsItemsSelector);
  const stores = useSelector(storeItemsSelector);
  const roles = useSelector(rolesGlobalsSelector);
  const user = useSelector(userviewUserSelector);
  const storeLoaded = useSelector(storeLoadedSelector);
  const [currentMarketplace, setCurrentMarketplace] = useState(null);
  const [currentRoleId, setCurrentRoleId] = useState(null);
  const [currentStore, setCurrentStore] = useState(null);
  const [currentPaqueryPoint, setCurrentPaqueryPoint] = useState(null);
  const [currentCity, setCurrentCity] = useState(null);
  const [cities, setCities] = useState([]);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    dispatch(actions.fetch(id));
  }, [id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (loaded && !user) {
      notification.error({
        message: 'Advertencia',
        description: `El usuario con id ${id} no fue encontrado.`,
      });
      setTimeout(() => {
        history.push('/users');
      }, 1500);
    }
  }, [loaded, user, id, history, dispatch]);

  useEffect(() => {
    const getUser = async () => {
      dispatch(actions.loading());
      disabledInputs.store = false;
      const citiesResponse = await getData(
        `${API.globals.cities}/${user.countryID}`,
      );
      if (user.marketplaceID) {
        dispatch(storeActions.all(user.marketplaceID));
        setCurrentMarketplace(user.marketplaceID);
      } else {
        disabledInputs.store = true;
      }
      if (user.ownerID !== user.marketplaceID) {
        setCurrentStore(user.ownerID);
      }
      dispatch(actions.setAvatar({ data: user.avatar }));
      setCities(citiesResponse);
      setCurrentCity(user.cityID);
      setCurrentPaqueryPoint(user.paqueryPointID);
      dispatch(actions.loaded());
    };
    if (user) {
      getUser();
    }
  }, [user, history, dispatch]);

  useEffect(() => {
    if (StoreRoles.includes(currentRoleId)) {
      if (currentMarketplace && stores.length === 0 && storeLoaded) {
        notification.info({
          message:
            'El marketplace no tiene tiendas. Carga al menos una para asignarla a este rol',
          description:
            'Te redirigiremos hacia el alta para que puedas continuar',
        });
        setTimeout(() => {
          history.push(`/marketplaces/${currentMarketplace}/stores/add`);
        }, 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores, currentRoleId]);

  const changeCountry = useCallback(
    async (countryId) => {
      dispatch(actions.loading());
      const citiesResponse = await getData(
        `${API.globals.cities}/${countryId}`,
      );
      setCities(citiesResponse);
      if (citiesResponse.length > 0) {
        setCurrentCity(citiesResponse[0].id);
      } else {
        setCurrentCity(undefined);
      }
      dispatch(actions.loaded());
    },
    [dispatch],
  );

  const selectUserRoleId = (roleId) => {
    setCurrentRoleId(roleId);
    if (MarketplaceRoles.includes(roleId) || StoreRoles.includes(roleId)) {
      if (marketplaces.length === 0) {
        notification.info({
          message:
            'Es necesario que cargues al menos un marketplace para asignarlo a este rol',
          description:
            'Te redirigiremos hacia el alta para que puedas continuar',
        });
        setTimeout(() => {
          history.push(SITELINKS.marketplace.add);
        }, 2000);
      }
    }
  };

  const selectMarketplace = (marketplaceId) => {
    setCurrentMarketplace(marketplaceId);
    dispatch(storeActions.all(marketplaceId));
    if (marketplaceId) {
      disabledInputs.store = false;
    }
  };

  const handleSubmit = async (values) => {
    dispatch(actions.loading());
    const payload = {
      Ammout: 0,
      Avatar: avatar.name,
      AvatarImg: avatar.name ? avatar.data.replace(IMAGE_REGEX, '') : null,
      CityID: values.cityID,
      CountryID: values.countryID,
      Email: values.email,
      ID: parseInt(id, 10),
      LastName: values.lastName,
      LogisticOperatorID: values.LogisticOperatorID || null,
      marketplaceID: values.marketplaceID,
      storeID: values.storeID,
      Mobile: parseInt(values.phone, 10),
      Name: values.name,
      paqueryPointID: values.paqueryPointID,
      StatusID: values.statusID,
      TermsAndConditions: values.termsAndConditions,
      UserRoleID: values.userRoleID,
      active: values.statusID === 6 ? false : undefined,
    };

    try {
      const response = await rest.post(API.user.update, payload);
      if (rest.isSuccessResponse(response)) {
        dispatch(userListActions.refreshPage());
        notification.success({
          message: 'Actualización de usuario',
          description: 'El usuario ha sido actualizado correctamente',
        });
        setTimeout(() => {
          history.push('/users');
        }, 1000);
      }
    } catch (error) {
      // Nothing to do on error
      console.log(error);
    } finally {
      dispatch(actions.loaded());
    }
  };

  if (!roles || !countries) {
    messageApi.open({
      type: 'error',
      content: 'Ocurrió un error al cargar los datos iniciales',
    });
    history.push('/users');
  }

  return (
    <>
      {contextHolder}
      <User
        disabledInputs={disabledInputs}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={user || defaultValues}
        marketplaces={marketplaces}
        paqueryPoints={paqueryPoints}
        stores={stores}
        countries={countries}
        cities={cities}
        roles={roles}
        currentMarketplace={currentMarketplace}
        currentStore={currentStore}
        currentPaqueryPoint={currentPaqueryPoint}
        currentCity={currentCity}
        setCurrentCountry={changeCountry}
        selectMarketplace={selectMarketplace}
        selectUserRoleId={selectUserRoleId}
        isUserModify
      />
    </>
  );
};

export default UserEdit;
